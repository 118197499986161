import React, { useEffect, useState } from "react";
import "./privacyPolicy.css";
import ReactMarkdown from "react-markdown";
import Header from "./header";

const PrivacyPolicy = () => {
  const [data, setData] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://raw.githubusercontent.com/matheuscaet/iamticket-public-docs/refs/heads/main/privacy-policy.md"
      );
      const data = await response.text();
      setData(data);
    };
    fetchData();
  }, []);

  return (
    <div className="privacy-policy">
      <Header />
      <div className="container-privacy-policy">
        <h1 className="privacy-title">Privacy Policy</h1>
        <ReactMarkdown>{data}</ReactMarkdown>
        <p>
          If you have any questions about this Privacy Policy, feel free to{" "}
          <a href="/contact">contact us</a>.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
