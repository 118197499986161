import React from "react";
import "./home.css";
import Header from "./header";

const Home = () => {
  return (
    <div className="App">
      <Header />
      {/* Hero Section with Parallax Effect */}
      <div className="parallax">
        <div className="overlay">
          <img
            src={
              "https://1e3mmzzlwzxdh0d1.public.blob.vercel-storage.com/Camada_1-Iv1yATRTkMVhhHn90EQ9vAHMNsPW4N.png"
            }
            alt="IamTicket Logo"
            className="logo"
          />
          <h1 className="main-title">Bem vindo ao I am Ticket</h1>
          <p className="main-subtitle">
            Acesso seguro e contínuo a eventos na ponta de seus dedos.
          </p>
          <div className="cta-buttons">
            <a href="#features" className="cta-button">
              Saiba mais
            </a>
            <a
              href="https://app.iamticket.com.br/"
              target="_blank"
              rel="noopener noreferrer"
              className="cta-button2"
            >
              Acessar plataforma
            </a>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <section className="features" id="features">
        <div className="container">
          <h2 className="section-title">Funcionalidades</h2>
          <div className="feature-list">
            <div className="feature-item">
              <h3>Fácil cadastro</h3>
              <p>
                Registre-se rapidamente em eventos fornecendo informações
                essenciais como seu nome, e-mail, número de telefone, identidade
                e foto facial.
              </p>
            </div>
            <div className="feature-item">
              <h3>Acesso biométrico</h3>
              <p>
                Aproveite o acesso rápido e sem contato, com dados faciais
                mapeados com segurança para armários biométricos.
              </p>
            </div>
            <div className="feature-item">
              <h3>Acesso com QR Code</h3>
              <p>
                Gere um código QR exclusivo para entrada em eventos sem
                complicações.
              </p>
            </div>
            <div className="feature-item">
              <h3>Tratamento seguro de dados</h3>
              <p>
                Todos os dados pessoais são armazenados de forma segura e em
                conformidade com os padrões de privacidade privacidade.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="how-it-works">
        <div className="container">
          <h2 className="section-title">Como funciona</h2>
          <p>
            A IamTicket está disponível para os eventos que são nossos
            parceiros. Os proprietários de eventos contratam os serviços da
            IamTicket, permitindo que os participantes usem o aplicativo para
            registro e acesso contínuos por meio de tecnologia biométrica ou de
            QR Code.
          </p>
          <p>
            Carregue informações pessoais precisas, incluindo um documento de
            identidade válido e uma foto facial, para garantir o sucesso do
            registro e do acesso.
          </p>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="cta">
        <div className="container">
          <h2 className="cta-title">
            Experimente o futuro do acesso a eventos
          </h2>
          <p>
            Faça o download da IamTicket hoje mesmo e transforme sua experiência
            em eventos com segurança, conveniência e inovação.
          </p>
          <a
            href="https://app.iamticket.com.br/"
            target="_blank"
            rel="noopener noreferrer"
            className="cta-button"
          >
            Acessar plataforma
          </a>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="footer">
        <div className="container">
          <p>© {new Date().getFullYear()} IamTicket. All rights reserved.</p>
          <p>
            <a href="/contact">Contato</a> |{" "}
            <a href="/privacy-policy">Politicas de privacidade</a>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Home;
