import React from "react";
import "./contact.css";
import Header from "./header";

const Contact = () => {
  return (
    <div className="contact">
      <Header />
      <div className="container-contact">
        <h1 className="contact-title">Fale conosco</h1>
        <p className="contact-subtitle">
          Tem dúvidas ou precisa de ajuda? Envie um email para:{" "}
          <a
            href="mailto:contato@iamconnection.com.br"
            className="contact-email"
          >
            contato@iamconnection.com.br
          </a>
        </p>
        <form className="contact-form">
          <div className="form-group">
            <label htmlFor="name">Nome</label>
            <input type="text" id="name" placeholder="Seu Name" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" placeholder="Seu Email" required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Mensagem</label>
            <textarea
              id="message"
              placeholder="Sua Mensagem"
              required
            ></textarea>
          </div>
          <button type="submit" className="contact-submit">
            Enviar
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
