import React from "react";
import { Link } from "react-router-dom";
import "./header.css";

const Header = () => {
  return (
    <header className="header">
      <div className="containerHeader">
        <div className="logo-container">
          <img src={"https://1e3mmzzlwzxdh0d1.public.blob.vercel-storage.com/Camada_1-Iv1yATRTkMVhhHn90EQ9vAHMNsPW4N.png"} alt="IamTicket Logo" className="logo" />
          {/* <h1 className="site-title">IamTicket</h1> */}
        </div>
        <nav className="nav">
          <ul className="nav-list">
            <li>
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>
            <li>
              <Link to="/contact" className="nav-link">
                Contato
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
